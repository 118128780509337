import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { User } from "./user";
import { AppConfigService } from "src/app/app-config/appconfig.service";

@Injectable({
  providedIn: "root",
})
export class GraphService {
  user: User = {
    displayName: "",
    groupIDs: [],
  };

  uri: string;

  constructor(private http: HttpClient, appconfig: AppConfigService) {
    this.uri = appconfig.protectedResources.graphApi.endpoint;
  }

  getGroups() {
    return this.http.get(this.uri);
  }

  getNextPage(nextPage: any) {
    return this.http.get(nextPage);
  }
}

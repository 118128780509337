import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { AdministrationVersionStatisticsComponent } from 'src/app/administration-version-statistics/administration-version-statistics.component';
import { SharedModule } from './../shared/shared.module';
import { SalaryService } from 'src/app/services/salary/salary.service';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import { UtilityService } from './../../services/utility/utility.service';
import { AdministrationSettingStatisticsComponent } from 'src/app/administration-setting-statistics/administration-setting-statistics.component';
import { VersionStatisticsFilterCheckboxComponent } from 'src/app/administration-version-statistics/filter-checkbox/filter-checkbox.component';
import { VersionStatisticsTableComponent } from 'src/app/administration-version-statistics/table/table.component';
import { SettingStatisticsTableComponent } from 'src/app/administration-setting-statistics/table/table.component';
import { BankSettingStatisticsTableComponent } from 'src/app/administration-banksetting-statistics/table/table.component';
import { SettingStatisticsFilterCheckboxComponent } from 'src/app/administration-setting-statistics/filter-checkbox/filter-checkbox.component';
import { SettingStatisticsRadiogroupComponent } from 'src/app/administration-setting-statistics/radiogroup/radiogroup.component';
import { VersionStatisticsRadiogroupComponent } from 'src/app/administration-version-statistics/radiogroup/radiogroup.component';
import { AdministrationBankSettingStatisticsComponent } from 'src/app/administration-banksetting-statistics/administration-banksetting-statistics.component';
import { BankSettingStatisticsFilterCheckboxComponent } from 'src/app/administration-banksetting-statistics/filter-checkbox/filter-checkbox.component';


const routes: Routes = [
      { path: 'admin/administration-setting-statistics', component: AdministrationSettingStatisticsComponent},
      { path: 'admin/administration-banksetting-statistics', component: AdministrationBankSettingStatisticsComponent},
      { path: 'admin/administration-version-statistics', component: AdministrationVersionStatisticsComponent },
      { path: 'admin/administration-setting-statistics', component:AdministrationSettingStatisticsComponent  },

];

@NgModule({
  declarations: [
    AdminComponent,
    VersionStatisticsFilterCheckboxComponent,
    AdministrationVersionStatisticsComponent,
    VersionStatisticsTableComponent,
    SettingStatisticsTableComponent,
    BankSettingStatisticsTableComponent,
    VersionStatisticsRadiogroupComponent,
    SettingStatisticsRadiogroupComponent,
    AdministrationSettingStatisticsComponent,
    AdministrationBankSettingStatisticsComponent,
    SettingStatisticsFilterCheckboxComponent,
    BankSettingStatisticsFilterCheckboxComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  providers:[SalaryService, AdministrationService, UtilityService ]
})
export class AdminModule { }

import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";

import _ from 'lodash';
import { BankSettingsModel } from "src/app/models/administration/bankSettings.model";

@Component({
  selector: "app-banksetting-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.css"],
})
export class BankSettingStatisticsTableComponent implements OnChanges, AfterViewChecked, OnInit {
  constructor(private cd: ChangeDetectorRef) { }

  @Input() bankSettingsData: BankSettingsModel[];
  @Input() viewMode: string;

   //sending from child comp to parent
    @Output() settingItemEvent = new EventEmitter<BankSettingsModel>();


  tempList: any[];
  listOfProperties: string[];
  isChecked:boolean = false;



  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.groupSettingsData(this.bankSettingsData) //Kan va fel ordning;

  }


  settingNewData(value: any) {
    //loopar igenom arrayen
    for(const n of value) {
      /* console.log(n) */
      this.settingItemEvent.emit(n);
  }
}

  groupSettingsData(a: BankSettingsModel[]) {

    //Using lodash, group data by period then sum each attribute
    if (a.length > 0) {
    let data = _(a).groupBy('period')
      .map(function (items, period) {
        return {
          Period: period,
          FlagAnnanBank: _.sumBy(items, 'flagAnnanBank'),
          FlagDanskeBankLB: _.sumBy(items, 'flagDanskeBankLB'),
          FlagDanskeBankXML: _.sumBy(items, 'flagDanskeBankXML'),
          FlagHandelsBankenLB: _.sumBy(items, 'flagHandelsBankenLB'),
          FlagHandelsBankenXML: _.sumBy(items, 'flagHandelsBankenXML'),
          FlagNordeaNOTCALB: _.sumBy(items, 'flagNordeaNOTCALB'),
          FlagNordeaNOTCAPO3: _.sumBy(items, 'flagNordeaNOTCAPO3'),
          FlagNordeaNOTCAXML: _.sumBy(items, 'flagNordeaNOTCAXML'),
          FlagNordeaCAXML: _.sumBy(items, 'flagNordeaCAXML'),
          FlagSebLB: _.sumBy(items, 'flagSebLB'),
          FlagSebXML: _.sumBy(items, 'flagSebXML'),
          FlagSwedBankLB: _.sumBy(items, 'flagSwedBankLB'),
          FlagSwedBankXML: _.sumBy(items, 'flagSwedBankXML'),
          FlagDNBXML: _.sumBy(items, 'flagDNBXML'),
          FlagLFXML: _.sumBy(items, 'flagLFXML'),

        };
      }).value();

    //Flip list so latest period comes first
    data.reverse()


    this.sortHeading(data);
    this.tempList = data;
    this.settingNewData(data);

  }
  else {
    console.log("1");
    if (a.length < 1) {
      console.log("2");

      _.forEach(this.tempList,(item, index) => {
       // console.log(item.Period)
        const period = item.Period;
        const arr =  _.zipObject(['Period','FlagAnnanBank','FlagDanskeBankLB','FlagDanskeBankXML','FlagHandelsBankenLB','FlagHandelsBankenXML','FlagNordeaNOTCALB','FlagNordeaNOTCAPO3','FlagNordeaNOTCAXML','FlagNordeaCAXML','FlagSebLB','FlagSebXML','FlagSwedBankLB','FlagSwedBankXML','FlagDNBXML','FlagLFXML' ], [period,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);

         this.tempList[index] = arr;

      })
    }
  }
  }

  sortHeading(settingsData: any) {

    //Take all properties of the first object in list
    const firstObjectInList = settingsData[0];
    const objectProperties = Object.keys(firstObjectInList);

    //Split each property name at capital letters and add a space then set it back in the list.
    for (let i = 0; i < objectProperties.length; i++) {
      objectProperties.splice(i,1, objectProperties[i].split(/(?=[A-Z])/).join(" "));
    }
    //Assign to list used as table columns header
    this.listOfProperties = objectProperties;

  }

  ngOnChanges() {
    this.groupSettingsData(this.bankSettingsData);
  }
}

<!-- <form>
  <select id="selectid" class="form-control-mb-12" (change)="exportTo($event.target.value)">
    <option> Export</option>
    <option value="pdf">Export to PDF</option>
    <option value="excel"> Export To EXCEL</option>
    <option value="excelAdmin"> Export To Admin EXCEL</option>
    <option value="csv">Export to CSV</option>
 </select>
</form> -->

<ng-container *ngIf="versionsData; then first; else (salary && second) || (settingData  && third)"></ng-container>
<ng-template #first>
  <select id="selectid" class="form-control-mb-12" (change)="exportTo($event.target.value)">
    <option> Export</option>
    <option value="pdf">Export to PDF</option>
    <option value="excelAdmin"> Export To EXCEL</option>
 </select>
</ng-template>
<ng-template #second>
  <select id="selectid" class="form-control-mb-12" (change)="exportTo($event.target.value)">
    <option> Export</option>
    <option value="pdf">Export to PDF</option>
    <option value="excel"> Export To EXCEL</option>
    <option value="csv">Export to CSV</option>
 </select>
</ng-template>
<ng-template #third>
  <select id="selectid" class="form-control-mb-12" (change)="exportTo($event.target.value)">
    <option> Export</option>
    <option value="pdf">Export to PDF</option>
    <option value="excelAdminSetting"> Export To EXCEL</option>
 </select>
</ng-template>


import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import _ from "lodash";
import {
  ApplicationVersions,
  ApplicationVersionsGrouped,
} from "src/app/models/administration/applicationversions.model";
import { UtilityService } from "./../../services/utility/utility.service";

@Component({
  selector: "app-version-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.css"],
})
export class VersionStatisticsTableComponent
  implements OnChanges, AfterViewChecked
{
  constructor(
    private cd: ChangeDetectorRef,
    private utService: UtilityService
  ) {}

  @Input() applicationVersions: ApplicationVersions[] = [];
  @Input() viewMode: string;
  //sending from child comp to parent
  @Output() versionItemEvent = new EventEmitter<ApplicationVersionsGrouped>();
  groupedVersionsData: ApplicationVersionsGrouped[];

  versionNewData(value: any) {
    //looping through array to later to emit
    for (const n of value) {
      console.log(n);
      this.versionItemEvent.emit(n);
    }
  }

  nonSQLVersions: string[];
  otherSQLVersions: string[];
  latestVersions: string[];
  selectedPeriod: Date = new Date();
  insertDate: Date;

  ngOnChanges(): void {
    // On changes to radiobutton
    this.setVersions(this.applicationVersions);
    this.groupVersionsData(this.applicationVersions);
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  groupVersionsData(applicationVersions: ApplicationVersions[]) {
    if (applicationVersions.length > 0) {
      this.groupedVersionsData = [];
      //Object with attributes for each period. keys => (202111,202112 etc) values => (Array(typeof ApplicationVersions))
      let groupedByPeriod = _.groupBy(applicationVersions, "period");
      //Latest period should be on top of table
      let sortedByPeriod =_.sortBy(groupedByPeriod,"Period").reverse();

      let period;
      //Using lodash foreach to iterate through the object.
      _.forEach(sortedByPeriod, (item, index) => {
        //Object with attributes for each application version. Map every item in each attribute list to a new object with the application version and a sum of installations
        const groupedByAppVersion = _(item)
          .groupBy("applicationVersion")
          .map(function (items, applicationVersion) {
            period = items[0].period;
            return {
              ApplicationVersion: applicationVersion,
              UnikaInstallationer: _.sumBy(items, "installations"),
            };
          })
          .value();

        const obj: ApplicationVersionsGrouped = {
          period: period,
          firstVersion: 0,
          secondVersion: 0,
          thirdVersion: 0,
          fourthVersion: 0,
          fifthVersion: 0,
          sixthVersion: 0,
          seventhVersion: 0,
          otherSQLVersions: 0,
          nonSQLVersions: 0,
          total: _.sumBy(groupedByAppVersion, "UnikaInstallationer"),
        };

        groupedByAppVersion.forEach(element => {
            if (element.ApplicationVersion === this.latestVersions[0]) {
              obj.firstVersion = element.UnikaInstallationer;
            } else if (element.ApplicationVersion === this.latestVersions[1]) {
              obj.secondVersion = element.UnikaInstallationer;
            } else if (element.ApplicationVersion === this.latestVersions[2]) {
              obj.thirdVersion = element.UnikaInstallationer;
            } else if (element.ApplicationVersion === this.latestVersions[3]) {
              obj.fourthVersion = element.UnikaInstallationer;
            } else if (element.ApplicationVersion === this.latestVersions[4]) {
              obj.fifthVersion = element.UnikaInstallationer;
            } else if (element.ApplicationVersion === this.latestVersions[5]) {
              obj.sixthVersion = element.UnikaInstallationer;
            } else if (element.ApplicationVersion === this.latestVersions[6]) {
              obj.seventhVersion = element.UnikaInstallationer;
            } else if (element.ApplicationVersion > "2017.1") {
              obj.otherSQLVersions += element.UnikaInstallationer;
            } else {
              obj.nonSQLVersions += element.UnikaInstallationer;
            }
        });

        this.groupedVersionsData.push(obj);
      });
    } else {
      //Fill list with 0s because it doesn't contain any ApplicationVersions objects meaning the groupVersionsData cannot perform it's calculations
      this.groupedVersionsData.forEach((data) => {
        data.firstVersion = 0;
        data.secondVersion = 0;
        data.thirdVersion = 0;
        data.fourthVersion = 0;
        data.fifthVersion = 0;
        data.sixthVersion = 0;
        data.seventhVersion = 0;
        data.otherSQLVersions = 0;
        data.nonSQLVersions = 0;
        data.total = 0;
      });
    }

    this.versionNewData(this.groupedVersionsData);
  }

  //Sets the 5 latest application versions in the table header
  setVersions(applicationVersions: ApplicationVersions[]) {
    const versions: string[] = _.uniqBy(
      applicationVersions,
      "applicationVersion"
    )
      .map((x) => x.applicationVersion)
      .sort()
      .reverse();
    this.latestVersions = versions.slice(0, 7);
  }

  calcToProcent(a: number, b: number) {
    if (
      (a === 0 && b === 0) ||
      a === undefined ||
      null ||
      b === undefined ||
      null
    ) {
      return 0 + "%";
    }

    let calc = a - b;
    // console.log(calc)
    let calc2 = a - calc;
    // console.log(calc2)
    if (a === b) {
      return (a % b).toString() + "%";
    } else {
      return Math.round((calc2 / a) * 100).toString() + "%";
    }
  }
}

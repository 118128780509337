import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApplicationVersionsGrouped } from 'src/app/models/administration/applicationversions.model';
import * as FileSaver from 'file-saver';
          declare var saveAs: typeof FileSaver.saveAs;

@Injectable({
  providedIn: 'root'
})
export class UtilityService {


  constructor(private toastr: ToastrService, private http: HttpClient) { }


  showWarning(message, title){
    this.toastr.warning(message, title);
}


showAlert(message, title, ToastConfig){
  this.toastr.error(message, title,  {
    timeOut: 4000,
    positionClass: 'toast-top-center',
  })
}


  exportToExcel(fileName: string, tableName: string) {
    /* pass here the table id */
    const element = document.getElementById(tableName);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }

  exportToCSV(data: any[], filename: string) {

    const columns = Object.keys(data[0]);

    const options = {
      fieldSeparator: ';',
      quoteStrings: '',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      headers: columns
    };
    new ngxCsv(data, filename, options);
  }



  exportExcel(a:any) {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(a); // Sale Data
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "lön");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {

    let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getUTCFullYear() + EXCEL_EXTENSION
      );
    });
  }


}

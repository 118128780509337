import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {


  transform(value: any, searchText: any): any {
    if (!searchText) {
      return value;
    }
    return value.filter((data) => this.matchValue(data, searchText));
  }

  matchValue(data, value) {
    return Object.keys(data).map((key) => {
       return new RegExp(value, 'gi').test(data[key]);
    }).some(result => result);
  }

/*   transform(items,searchTerm){
    let filteredList=[];
    if(searchTerm){
    let newSearchTerm=!isNaN(searchTerm)? searchTerm.toString(): searchTerm.toString().toUpperCase();
    let prop;
    return items.filter(item=>{
    for(let key in item){
    prop=isNaN(item[key]) ? item[key].toString().toUpperCase() : item[key].toString();
    if(prop.indexOf(newSearchTerm) > -1){
    filteredList.push(item);
    return filteredList;}
    }
    })
    }
    else{
    return items;
    }}
 */
}

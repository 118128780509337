import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RootSalary } from "../../models/salary/rootsalary.model";
import { AppConfigService } from "./../../app-config/appconfig.service";

@Injectable({
  providedIn: "root",
})
export class SalaryService {
  constructor(
    private http: HttpClient,
    private _appConfigService: AppConfigService
  ) {}

  // get salary by period details
  getSalaryByPeriod(period?: string): Observable<RootSalary[]> {
    if (period === null && period === undefined) {
      const selectedPeriod = new Date();
      period = this.dateFormat(selectedPeriod);
    }
    return this.http.get<RootSalary[]>(
      this._appConfigService.telemetryApiUrl +
        "Salary/GetSalaryTelemetries?period=" +
        period, {headers: new HttpHeaders({'Content-Type': 'application/json'})}
    );
  }

  // get all salary details
  getAllRoot(): Observable<RootSalary[]> {
    return this.http.get<RootSalary[]>(
      this._appConfigService.telemetryApiUrl + "Salary/GetSalaryTelemetries", {headers: new HttpHeaders({'Content-Type': 'application/json'})}
    );
  }

  dateFormat(selectedPeriod: Date) {
    if (selectedPeriod != null) {
      const presentableMonth = selectedPeriod.getMonth() + 1;

      let presentableDate = "";

      if (presentableMonth < 10) {
        presentableDate =
          selectedPeriod.getFullYear().toString() +
          "-0" +
          presentableMonth.toString();
      } else {
        presentableDate =
          selectedPeriod.getFullYear().toString() +
          "-" +
          presentableMonth.toString();
      }
      return presentableDate;
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LonDataTableComponent } from './../../lon-data-table/lon-data-table.component';
import { SalaryService } from 'src/app/services/salary/salary.service';
import { SharedModule } from '../shared/shared.module';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import { en_GB, NZ_I18N, sv_SE} from 'ng-zorro-antd/i18n';
import { NzI18nService, zh_CN } from 'ng-zorro-antd/i18n';
import { VudDateNativeAdapter, VudDateAdapter, VudDatepickerModule } from '@vismaux/ngx-vud';


const routes: Routes = [
  { path: 'lon', component: LonDataTableComponent }
];

@NgModule({
  declarations: [
    LonDataTableComponent,
  ],
  imports: [
    SharedModule,
    VudDatepickerModule,
    RouterModule.forChild(routes)
  ],
  providers:[SalaryService, UtilityService, AdministrationService, NzI18nService,
    { provide: VudDateAdapter, useClass: VudDateNativeAdapter },
    { provide: NZ_I18N, useValue: en_GB },
,]
})
export class LonModule { }




<!--Top navbar with sticky position-->
<vud-navbar class="customNav">
  <!--Left sided brand (name of web app)-->

  <vud-navbar-brand routerLink="/admin/administration-setting-statistics" *ngIf="isLoggedInAsAdmin && !isLoggedInAsSalary">Visma Telemetry Admin Tool - Administration</vud-navbar-brand>
  <vud-navbar-brand routerLink="/lon" routerLinkActive="active" *ngIf="isLoggedInAsSalary && !isLoggedInAsAdmin">Visma Telemetry Admin Tool - Lön</vud-navbar-brand>
  <vud-navbar-brand routerLink="/lon" routerLinkActive="active" *ngIf="isLoggedInAsSalary && isLoggedInAsAdmin">Visma Telemetry Admin Tool - Administration & Lön</vud-navbar-brand>
  <!--Navigation options-->
  <vud-navbar-menu>
    <vud-navbar-menu-item label="Lön"  routerLink="/lon" routerLinkActive="active" *ngIf="isLoggedInAsSalary"></vud-navbar-menu-item>
    <vud-navbar-menu-item label="Inställningsstatistik" routerLink="/admin/administration-setting-statistics" routerLinkActive="active" *ngIf="isLoggedInAsAdmin"></vud-navbar-menu-item>
    <vud-navbar-menu-item label="Bankstatistik" routerLink="/admin/administration-banksetting-statistics" routerLinkActive="active" *ngIf="isLoggedInAsAdmin"></vud-navbar-menu-item>
    <vud-navbar-menu-item label="Versionsstatistik" routerLink="/admin/administration-version-statistics" routerLinkActive="active" *ngIf="isLoggedInAsAdmin"></vud-navbar-menu-item>

  </vud-navbar-menu>

  <!--User details-->
  <!-- <vud-navbar-right-menu>
    <li
      #dropdown="ncDropdown"
      ncDropdown
      class="dropdown user-dropdown"
      [class.open]="dropdown.isOpen$ | async"
    >
      <a ncDropdownToggle class="dropdown-toggle" (click)="dropdown.toggle()">
        {{ user?.name }}
        <span class="vismaicon vismaicon-menu vismaicon-user"></span>
        <small>{{ user?.mail }}</small>
        <span class="caret"></span>
      </a>
      <ul ncDropdownMenu class="dropdown-menu company-selection">
        <li class="tw-overflow-visible">
          <a
            (click)="logout()"
            class="d-flex align-items-center"
            data-test="sign-out-link"
          >
            <span class="vismaicon vismaicon-logout tw-pr-2"></span>
            <span i18n>Logga ut</span>
          </a>
        </li>
      </ul>
    </li>
  </vud-navbar-right-menu> -->


  <vud-navbar-right-menu>
    <ul class="nav navbar-nav navbar-right first-level">
      <li role="none" vuddropdown class="dropdown user-dropdown" (click)="openProfileDropdown=!openProfileDropdown" [ngClass]="openProfileDropdown ? 'open':''">
        <a vuddropdowntoggle role="button" draggable="false" class="dropdown-toggle companyDropdown" aria-expanded="false"> 
          {{ user?.name }}
          <small>{{ user?.mail }}</small>
          <span class="caret"></span>
        </a>
        <ul vuddropdownmenu class="dropdown-menu company-selection">
          <li><a (click)="logout()" class="log-out-link vismaicon vismaicon-sm vismaicon-dynamic vismaicon-logout">Logga ut</a></li>
        </ul>
      </li>
    </ul>
  </vud-navbar-right-menu>
</vud-navbar>

<div class="panel">
  <div class="panel-heading">
      <h1 class="panel-title">
          Inkludera
      </h1>
  </div>
  <div class="panel-body">

  <label class="label-content">
    <input type="checkbox" [(ngModel)]="checkboxLabels.a200" (ngModelChange)="filterChange()">
    <span> Admin 200</span>
  </label>
  <label class="label-content">
    <input type="checkbox" [(ngModel)]="checkboxLabels.a500" (ngModelChange)="filterChange()">
    <span> Admin 500</span>
  </label>
  <label class="label-content">
    <input type="checkbox" [(ngModel)]="checkboxLabels.a1000" (ngModelChange)="filterChange()">
    <span> Admin 1000</span>
  </label>
  <label class="label-content">
    <input type="checkbox" [(ngModel)]="checkboxLabels.a2000" (ngModelChange)="filterChange()">
    <span> Admin 2000</span>
  </label>
  <label class="label-content">
    <input type="checkbox" [(ngModel)]="checkboxLabels.fakturering" (ngModelChange)="filterChange()">
    <span> Fakturering</span>
  </label>
  <label class="label-content">
    <input type="checkbox" [(ngModel)]="checkboxLabels.forening" (ngModelChange)="filterChange()">
    <span> Förening</span>
  </label>
</div>
<div class="container-fluid">
  <div *ngIf="!loading" class="row">
    <div *ngIf="this.bankSettingsData" class="col-9 d-flex">
      <h1>Bankstatistik</h1>
      <span class="insertDate"> Data är från: {{ insertDate | date }} </span>
      <app-export
      *ngIf="bankSettingData"
        [settingData]="bankSettingData"
        class="ml-auto"
       ></app-export>
      <app-banksetting-table
       class="table-responsive w-100 h-auto"
        [bankSettingsData]="this.bankSettingsData"
      [viewMode]="this.viewMode"
      (settingItemEvent)="addBankSettingData($event)"
      >
      </app-banksetting-table>
    </div>

    <div class="col-3">
      <app-banksetting-radiogroup (viewModeEmitter)="this.viewMode = $event"></app-banksetting-radiogroup>
      <app-banksetting-filter-checkbox [bankSettingsData]="this.bankSettingsData" (filterEmitter)="this.bankSettingsData = $event">
      </app-banksetting-filter-checkbox>
    </div>
  </div>
</div>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SalaryService } from '../services/salary/salary.service';
import { RootSalary } from '../models/salary/rootsalary.model';


@Component({
  selector: 'app-lon-data-table-component',
  templateUrl: './lon-data-table.component.html',
  styleUrls: ['./lon-data-table.component.css']
})
export class LonDataTableComponent implements OnInit {

  @ViewChild('htmlData') htmlData: ElementRef;

  selectedPeriod: Date = new Date();
  salary: RootSalary[] = [];
  searchTerm = '';
  oldSalary: RootSalary[] = [];
  root: RootSalary[] = [];
  dataAvailable: boolean;
  isBigger = true;
  isSubmitted = false;
  data: any;

  constructor(
    private salaryService: SalaryService
  ) { }


  calcToProcent(a: number, b: number) {
      if (a === null) {
          return "No data"
      } else if (a === 0) {
        return (a).toString() + '%';
      } else if (a === b) {
          return (a % b).toString() + '%';
      } else {
          return (Math.round(((b-a) / a) * 100)).toString() + '%';
      }
    }

    countTotal(a?: number, b?: number, c?: number) {
      if (a === null || b=== null || c === null)  {
        return null
      }
      return a + b + c;
    }

  getSelectedByPeriod() {
    if (this.selectedPeriod !== null || undefined) {
      this.salaryService
        .getSalaryByPeriod(this.salaryService.dateFormat(this.selectedPeriod))
        .subscribe(
          (data: RootSalary[]) => (this.salary = data
            )
        );

      setTimeout(() => {
        this.getOneYearBack();
      }, 300);
    }
  }

  checkForData() {
    return !(this.salary.length === 0)
  }

  /* Using this method to get data from 1 year ago from the date we click, we call it on getSelectByPeriod */
  getOneYearBack() {
    let period: any = '';
   
    if (this.selectedPeriod) {
      period = this.selectedPeriod;
      this.salaryService
        .getSalaryByPeriod(this.salaryService.dateFormat(new Date(period.getFullYear(), - 1)))
        .subscribe(
          (data: RootSalary[]) => (this.oldSalary = data,
            this.dataAvailable = this.checkForData()
            )
        )
        
    }
  }

  ngOnInit() {
    this.getSelectedByPeriod();
    //this.getAllRoot();
  }
}

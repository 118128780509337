<header>
  <app-nav-menu></app-nav-menu>
</header>
<div class="container-fluid" id="container">
  <div class="row" id="row">
    <div class="col-md-12">
      <router-outlet *ngIf="!isIframe"></router-outlet> <!-- *ngIf="!error && loaded"-->
      <footer *ngIf="loginDisplay"></footer>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div *ngIf="!loading" class="row">
    <div *ngIf="this.settingsData" class="col-9 d-flex">
      <h1>Inställningsstatistik</h1>
      <span class="insertDate"> Data är från: {{ insertDate | date }} </span>
      <app-export
      *ngIf="settingData"
        [settingData]="settingData"
        class="ml-auto"
       ></app-export>
      <app-setting-table
       class="table-responsive w-100 h-auto"
        [settingsData]="this.settingsData"
      [viewMode]="this.viewMode"
      (settingItemEvent)="addSettingData($event)"
      >
      </app-setting-table>
    </div>

    <div class="col-3">
      <app-setting-radiogroup (viewModeEmitter)="this.viewMode = $event"></app-setting-radiogroup>
      <app-setting-filter-checkbox [settingsData]="this.settingsData" (filterEmitter)="this.settingsData = $event">
      </app-setting-filter-checkbox>
    </div>
  </div>
</div>

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { AdministrationService } from './services/administration/administration.service';
import { SalaryService } from './services/salary/salary.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'Visma Telemetry Admin Tool';
  error = false;
  loaded = true; // change when implementing error page
  isIframe = false;
  loginDisplay = false;
  
 
  
  
}

import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { catchError, lastValueFrom, map, Observable, throwError } from "rxjs";
import { AppConfigService } from "src/app/app-config/appconfig.service";
import { SettingsModel } from "src/app/models/administration/settings.model";
import { AdminTelemetryEntry } from "../../models/administration/administration.model";
import { ApplicationVersions } from "../../models/administration/applicationversions.model";
import { BankSettingsModel } from "src/app/models/administration/bankSettings.model";

@Injectable({
  providedIn: "root",
})
export class AdministrationService {
  constructor(
    private httpClient: HttpClient,
    private _appConfigService: AppConfigService
  ) {}

  async getDataForPeriod(startPeriod: Date, endPeriod: Date) {
    /*
    * Fetch telemetry data from API in selected period and return as list of AdminTelemetryEntry (see interface)
      ### API method is missing! ###
    */

    try {
      return await this.httpClient
        .get<AdminTelemetryEntry[]>(
          this._appConfigService.telemetryApiUrl +
            "FactAdminOnOpenCompanyTelemetry/GetByPeriod?start=" +
            startPeriod.toJSON() +
            "&end=" +
            endPeriod.toJSON()
        )
        .toPromise();
    } catch (e) {
      console.error("Error: ", e);
    }
  }

  getAdminData(
    startPeriod: Date,
    endPeriod: Date
  ): Observable<ApplicationVersions[]> {
    try {
      return this.httpClient.get<ApplicationVersions[]>(
        this._appConfigService.telemetryApiUrl +
          "Admin/GetApplicationVersions?start=" +
          startPeriod.toJSON() +
          "&end=" +
          endPeriod.toJSON()
      );
    } catch (e) {
      console.error("Error: ", e);
    }
  }
  /* Getting Settings data */
  getAdminSettingsData(
    startPeriod: Date,
    endPeriod: Date
  ): Observable<SettingsModel[]> {
    return this.httpClient
      .get(
        `${
          this._appConfigService.telemetryApiUrl
        }Admin/GetSettings?start=${startPeriod.toJSON()}&end=${endPeriod.toJSON()}`
      )
      .pipe(
        map((data: SettingsModel[]) => {
          return data;
        }),
        catchError((error) => {
          return throwError("SettingsData not Found!");
        })
      );
  }

  /* Getting BankSettings data */
  getAdminBankSettingsData(
    startPeriod: Date,
    endPeriod: Date
  ): Observable<BankSettingsModel[]> {
    return this.httpClient
      .get(
        `${
          this._appConfigService.telemetryApiUrl
        }Admin/GetBankSettings?start=${startPeriod.toJSON()}&end=${endPeriod.toJSON()}`
      )
      .pipe(
        map((data: BankSettingsModel[]) => {
          return data;
        }),
        catchError((error) => {
          return throwError("BankSettingsData not Found!");
        })
      );
  }

  dateFormat(selectedPeriod: Date) {
    if (selectedPeriod != null) {
      const presentableMonth = selectedPeriod.getMonth() + 1;

      let presentableDate = "";

      if (presentableMonth < 10) {
        presentableDate =
          selectedPeriod.getFullYear().toString() +
          "-0" +
          presentableMonth.toString();
      } else {
        presentableDate =
          selectedPeriod.getFullYear().toString() +
          "-" +
          presentableMonth.toString();
      }
      return presentableDate;
    }
  }
}

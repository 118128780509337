<!--For rounding of percentages-->
<script src="https://unpkg.com/largest-remainder-round"></script>

<table class="table" id="htmlData">
  <thead>
    <tr>
      <td>Period</td>
      <td>Unika Företag</td>
      <td>Unika Installationer</td>
      <td>Husarbete</td>
      <td>Aktiverat Von</td>
      <td>EU Bas</td>
      <td>NE</td>
      <td>Bas9X</td>
      <td>Ta emot e-faktura</td>
      <td>Ta emot e-order</td>
      <td>E-faktura - scanning</td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of tempList">
      <td>{{ item.Period | monthYear }}</td>
      <td>{{ item.UnikaForetag }}</td>
      <td>{{ item.UnikaInstallationer }}</td>
      <td>
        <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="elseHus">
          {{ item.Husarbete }}
        </ng-template>
        <ng-template #elseHus>
          {{ calcToProcent(item.UnikaForetag, item.Husarbete) }}
        </ng-template>
      </td>
      <td>
        <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="elseAktiveratVon">
          {{ item.AktiveratVon }}
        </ng-template>
        <ng-template #elseAktiveratVon>
          {{ calcToProcent(item.UnikaForetag, item.AktiveratVon) }}
        </ng-template>
      </td>
      <td>
        <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="elseEUBas">
          {{ item.EUBas }}
        </ng-template>
        <ng-template #elseEUBas>
          {{ calcToProcent(item.UnikaForetag, item.EUBas) }}
        </ng-template>
      </td>
      <td>
        <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="elseNE">
          {{ item.NE }}
        </ng-template>
        <ng-template #elseNE>
          {{ calcToProcent(item.UnikaForetag, item.NE) }}
        </ng-template>
      </td>
      <td>
        <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="elseBas9X">
          {{ item.Bas9X }}
        </ng-template>
        <ng-template #elseBas9X>
          {{ calcToProcent(item.UnikaForetag, item.Bas9X) }}
        </ng-template>
      </td>
      <td>
        <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="elseInvReceiver">
          {{ item.InvReceiver }}
        </ng-template>
        <ng-template #elseInvReceiver>
          {{ calcToProcent(item.UnikaForetag, item.InvReceiver) }}
        </ng-template>
      </td>
      <td>
        <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="elseOrdReceiver">
          {{ item.OrdReceiver }}
        </ng-template>
        <ng-template #elseOrdReceiver>
          {{ calcToProcent(item.UnikaForetag, item.OrdReceiver) }}
        </ng-template>
      </td>
      <td>
        <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="elseScanReceiver">
          {{ item.ScanReceiver }}
        </ng-template>
        <ng-template #elseScanReceiver>
          {{ calcToProcent(item.UnikaForetag, item.ScanReceiver) }}
        </ng-template>
      </td>
    </tr>
  </tbody>
</table>

import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AgChartsAngularModule } from "ag-charts-angular";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { ErrorComponent } from "./error/error.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";

import { VudModalModule, VudNavbarModule, VudDateNativeAdapter, VudDateAdapter, VudDatepickerModule } from '@vismaux/ngx-vud';

import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from "@angular/platform-browser/animations";
import { UtilityService } from "./services/utility/utility.service";
import { AdministrationService } from "./services/administration/administration.service";
import { SalaryService } from "./services/salary/salary.service";
import { ToastrModule } from "ngx-toastr";
import { en_GB, NZ_I18N } from "ng-zorro-antd/i18n";
import { AppRoutingModule } from "./app-routing";
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";

import { OverageComponent } from "./overage/overage.component";
const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;
import { AppConfigService } from "./app-config/appconfig.service";

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(
  appConfig: AppConfigService
): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: appConfig.auth.clientId,
      authority: appConfig.auth.authority,
      redirectUri: appConfig.auth.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback(logLevel: LogLevel, message: string) {
          console.log(message);
        },
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
    },
  });
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(
  appConfig: AppConfigService
): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(
    `${appConfig.protectedResources.telemetryAPI.endpoint}`, [`${appConfig.protectedResources.telemetryAPI.scopes}`]);
  protectedResourceMap.set(`${appConfig.protectedResources.graphApi.endpoint}`, [`${appConfig.protectedResources.graphApi.scopes}`]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(
  appConfig: AppConfigService
): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: appConfig.loginRequest,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavMenuComponent,
    OverageComponent,
  ],

  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MsalModule,
    HttpClientModule,
    AgChartsAngularModule,
    VudModalModule,
    VudNavbarModule,
    VudDatepickerModule,
    AgChartsAngularModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: "toast-top-right",
    }),
    RouterModule.forRoot(
      [
        { path: "", component: HomeComponent, pathMatch: "full" },
        {
          path: "admin",
          loadChildren: () =>
            import("./modules/admin/admin.module").then((m) => m.AdminModule),
        },
        {
          path: "lon",
          loadChildren: () =>
            import("./modules/lon/lon.module").then((m) => m.LonModule),
        },
        { path: "404", component: ErrorComponent },
        { path: "**", redirectTo: "/404" },
      ]
    ),
  ],
  providers: [
    UtilityService,
    AdministrationService,
    SalaryService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          return appConfigService.loadAppConfig();
        };
      },
    },
    DatePipe,
    { provide: VudDateAdapter, useClass: VudDateNativeAdapter },
    { provide: NZ_I18N, useValue: en_GB },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AppConfigService],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }

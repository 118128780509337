import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { AccountInfo } from "@azure/msal-common";
import { MsalService } from "@azure/msal-angular";
import { GraphService } from "./graph.service";
import { AppConfigService } from "src/app/app-config/appconfig.service";

interface Account extends AccountInfo {
  idTokenClaims?: {
    preferred_username?: string;
    groups?: string[];
    _claim_names?: {
      groups: string | string[];
    };
    _claim_sources?: {
      src1: {
        endpoint: string | string[];
      };
    };
  };
}

@Injectable({
  providedIn: "root",
})
export class GroupGuardService  {
  constructor(
    private authService: MsalService,
    private graphService: GraphService,
    private router: Router,
    private appConfig: AppConfigService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let expectedGroup;
    if (route.data.expectedGroup == "LON") {
      expectedGroup = this.appConfig.groups.groups.groupSalary;
    }
    if (route.data.expectedGroup == "ADMIN") {
      expectedGroup = this.appConfig.groups.groups.groupAdmin;
    }

    let account: Account = this.authService.instance.getAllAccounts()[0];

    this.graphService.user.displayName =
      account.idTokenClaims?.preferred_username!;

    if (
      account.idTokenClaims?.groups?.includes(expectedGroup) ||
      this.graphService.user.groupIDs.includes(expectedGroup)
    ) {
      return true;
    }

    if (account.idTokenClaims?.groups) {
      this.graphService.user.groupIDs = account.idTokenClaims?.groups;
    } else {
      if (account.idTokenClaims?._claim_names) {
        window.alert(
          "You have too many group memberships. The application will now query Microsoft Graph to get the full list of groups that you are a member of."
        );
        this.router.navigate(["/overage"]);
        return false;
      }

      window.alert("Token does not have groups claim.");
      return false;
    }

    window.alert("You do not have access for this.");
    return false;
  }
}

import { Component, OnInit, Output } from '@angular/core';

import { groupBy, mergeMap, reduce, toArray } from 'rxjs/operators';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import { SettingsModel } from '../models/administration/settings.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-administration-setting-statistics',
  templateUrl: './administration-setting-statistics.component.html',
  styleUrls: ['./administration-setting-statistics.component.css']
})
export class AdministrationSettingStatisticsComponent implements OnInit {

  settingsData: SettingsModel[];
  insertDate: Date;
  loading: boolean = true;
  viewMode: string;


  // for sending data to export
  settingData: any = [];

  addSettingData(newItem: any) {
    this.settingData.push(newItem);
   // console.log(this.settingData)
  }



  constructor(private adminService: AdministrationService) { }

  ngOnInit(): void {
    this.settingsData = [];
    this.getSettingsData();

  }

/* testdata(a:SettingsModel[]) {

let test = _(a).groupBy('period')
.map(function(items, period) {
  return {
    Period: period,
    UnikaForetag: _.sumBy(items, 'uniqueCorporateIdentityNumberId'),
    UnikaInstallationer: _.sumBy(items, 'uniqueCustomerNumberId'),
    Husarbete: _.sumBy(items, 'flagHusarb'),
    AktiveratVON: _.sumBy(items, 'flagVo'),

  };
}).value();

test.reverse()

this.groupedSettingsData = test;

}
 */

    //Flip list so latest period comes first
/*     data.reverse()

    this.groupedSettingsData = data;

  } */

  setInsertDate(data: SettingsModel[]) {
    // get the insertDate of the last inserted row (from datacollection project)
    this.insertDate = new Date(Math.max.apply(null, data.map(function(e) {
      return new Date(e.insertDate);
    })));
  }

  /* Fetch the Settings data from admin service */
  getSettingsData() {
    const startPeriod = new Date();
    const endPeriod = new Date();

    startPeriod.setFullYear(endPeriod.getFullYear() - 1);
    startPeriod.setMonth(endPeriod.getMonth());

    this.adminService.getAdminSettingsData(startPeriod, endPeriod).subscribe((data: SettingsModel[]) => {
      this.settingsData = data;
      this.setInsertDate(data);
      this.loading = false;
    })

  }

}

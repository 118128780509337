<div class="panel">
  <div class="panel-heading">
      <h1 class="panel-title">
          Inkludera
      </h1>
  </div>
  <div class="panel-body">

    <label class="label-content">
    <input type="checkbox" [(ngModel)]="settingCheckboxLabels.a200" (ngModelChange)="settingsFilterChange()">
    <span> Admin 200</span>
  </label>
  <label class="label-content">
    <input type="checkbox" [(ngModel)]="settingCheckboxLabels.a500" (ngModelChange)="settingsFilterChange()">
    <span> Admin 500</span>
  </label>
  <label class="label-content">
    <input type="checkbox" [(ngModel)]="settingCheckboxLabels.a1000" (ngModelChange)="settingsFilterChange()">
    <span> Admin 1000</span>
  </label>
  <label class="label-content">
    <input type="checkbox" [(ngModel)]="settingCheckboxLabels.a2000" (ngModelChange)="settingsFilterChange()">
    <span> Admin 2000</span>
  </label>
  <label class="label-content">
    <input type="checkbox" [(ngModel)]="settingCheckboxLabels.fakturering" (ngModelChange)="settingsFilterChange()">
    <span> Fakturering</span>
  </label>
  <label class="label-content">
    <input type="checkbox" [(ngModel)]="settingCheckboxLabels.forening" (ngModelChange)="settingsFilterChange()">
    <span> Förening</span>
  </label>
  
<div>
  <h3 class="start-title">Start byrå</h3>
 <form class="radio-content">
  <label class="label-content">
    <input checked type="radio"   name="selection"  value="both" (change)="onItemChange($event)"/>
    <span> True&False</span>
  </label>

  <label class="label-content">
    <input type="radio"  name="selection"  value="{{true}}"  (change)="onItemChange($event)"/>
    <span> True</span>
  </label>

  <label class="label-content">
    <input type="radio"  name="selection"  value="{{false}}" (change)="onItemChange($event)"/>
    <span> False</span>
  </label>
  
 </form>
</div>
</div>



import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { Sort } from 'src/app/lon-data-table/sort';


@Directive({
  selector: '[appSort]'
})
export class SortParamsDirective {
  @Input() appSort!: Array<any>;
  constructor(private renderer: Renderer2, private targetElem: ElementRef) { }

  @HostListener('click')
  sortData() {
    // Create Object of Sort Class
    const sort = new Sort();
    // Get Reference Of Current Clicked Element
    const elem = this.targetElem.nativeElement;
    // Get In Which Order list should be sorted by default it should be set to desc on element attribute
    const order = elem.getAttribute('data-order');
    // Get The Property Type specially set [data-type=date] if it is date field
    const type = elem.getAttribute('data-type');
    // Get The Property Name from Element Attribute
    const property = elem.getAttribute('class');

    if (order === 'desc') {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute('data-order', 'asc');
    } else {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute('data-order', 'desc');
    }
  }

}

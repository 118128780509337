import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-version-radiogroup',
  templateUrl: './radiogroup.component.html',
  styleUrls: ['./radiogroup.component.css']
})
export class VersionStatisticsRadiogroupComponent implements OnInit {

  viewOptions: UntypedFormGroup;
  @Output() viewModeEmitter = new EventEmitter<string>();

  constructor(fb: UntypedFormBuilder) {
    this.viewOptions = fb.group({
      selection: 'Antal'
    });
  }

  ngOnInit() {
    this.emitEvent();
  }

  emitEvent() {
    this.viewModeEmitter.emit(this.viewOptions.value.selection);
  }

}

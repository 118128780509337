<div class="row error-page align-items-center">
  <div class="error-page-content col-12 col-md-6">
    <h1>Server error</h1>
    <p>We're sorry, an unexpected error has occurred and it has been logged. We will try to fix it as soon as possible.</p>
    <p class="error-page-home-link">
      <a class="btn btn-primary"  href="javascript:window.location=''" >Go to home page</a>
    </p>
  </div>
  <div class="error-page-image col-12 col-md-6">
 <!--    <img src="../../../dist/img/error-pages/server_error.svg" alt="Server error"> -->
  </div>
</div>

import { NgModule } from '@angular/core';

import { VudModalModule, VudNavbarModule, VudDateNativeAdapter, VudDateAdapter, VudDatepickerModule } from '@vismaux/ngx-vud';



import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from 'src/app/pipes/filter/filter.pipe';
import { MonthYearPipe } from 'src/app/pipes/month-year/month-year.pipe';
import { NoCommaPipe } from 'src/app/pipes/no-comma/no-comma.pipe';
import { SortParamsDirective } from 'src/app/pipes/sort/sort-params.directive';
import { SortPipe } from 'src/app/pipes/sort/sort.pipe';
import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UtilityService } from './../../services/utility/utility.service';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import { registerLocaleData, CommonModule } from '@angular/common';
import en from '@angular/common/locales/en';
import sv from '@angular/common/locales/sv';
import { en_GB, NZ_I18N, sv_SE} from 'ng-zorro-antd/i18n';
import { NzI18nService, zh_CN } from 'ng-zorro-antd/i18n';
registerLocaleData(en);
import { LOCALE_ID } from '@angular/core';
import {  NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ExportComponent } from 'src/app/shared/export/export.component';
import { ErrorComponent } from 'src/app/error/error.component';
import { SalaryService } from 'src/app/services/salary/salary.service';
import { HammerModule } from '@angular/platform-browser';
import { ClickOutsideModule } from 'ng-click-outside';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';




@NgModule({
  declarations: [
    ExportComponent,
    FilterPipe,
    SortPipe,
    SortParamsDirective,
    NoCommaPipe,
    MonthYearPipe,
    ErrorComponent,
  ],
  imports: [
  CommonModule,
  ClickOutsideModule,
  FormsModule,
  ReactiveFormsModule,
  NzDatePickerModule,
  HammerModule,
  TableModule,
    DropdownModule,
    ButtonModule,
  FontAwesomeModule,
  ToastrModule.forRoot({
    timeOut: 2000,
    positionClass: 'toast-top-right',
  }),
  ],
  providers: [UtilityService,AdministrationService, SalaryService,NzI18nService,
    { provide: VudDateAdapter, useClass: VudDateNativeAdapter },
    { provide: NZ_I18N, useValue: en_GB },
  ],
  exports: [
    ExportComponent,
    FilterPipe,
    SortPipe,
    SortParamsDirective,
    NoCommaPipe,
    MonthYearPipe,
    ErrorComponent,
    VudDatepickerModule,
    ClickOutsideModule,
    FormsModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    CommonModule,
    TableModule,
    DropdownModule,
    ButtonModule


],
})
export class SharedModule { }

import { Component, OnInit, Output } from '@angular/core';

import { groupBy, mergeMap, reduce, toArray } from 'rxjs/operators';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import * as _ from 'lodash';
import { BankSettingsModel } from '../models/administration/bankSettings.model';

@Component({
  selector: 'administration-banksetting-statistics',
  templateUrl: './administration-banksetting-statistics.component.html',
  styleUrls: ['./administration-banksetting-statistics.component.css']
})
export class AdministrationBankSettingStatisticsComponent implements OnInit {

  bankSettingsData: BankSettingsModel[];
  insertDate: Date;
  loading: boolean = true;
  viewMode: string;


  // for sending data to export
  bankSettingData: any = [];

  addBankSettingData(newItem: any) {
    this.bankSettingData.push(newItem);
   // console.log(this.settingData)
  }



  constructor(private adminService: AdministrationService) { }

  ngOnInit(): void {
    this.bankSettingsData = [];
    this.getBankSettingsData();

  }

  setInsertDate(data: BankSettingsModel[]) {
    // get the insertDate of the last inserted row (from datacollection project)
    this.insertDate = new Date(Math.max.apply(null, data.map(function(e) {
      return new Date(e.insertDate);
    })));
  }

  /* Fetch the Settings data from admin service */
  getBankSettingsData() {
    const startPeriod = new Date();
    const endPeriod = new Date();

    startPeriod.setFullYear(endPeriod.getFullYear() - 1);
    startPeriod.setMonth(endPeriod.getMonth());

    this.adminService.getAdminBankSettingsData(startPeriod, endPeriod).subscribe((data: BankSettingsModel[]) => {
      console.log(data);
      this.bankSettingsData = data;
      this.setInsertDate(data);
      this.loading = false;
    })

  }

}

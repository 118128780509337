import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppConfigService {
  private appConfig: any;
  private httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  async loadAppConfig() {
    return await lastValueFrom(
      this.httpClient.get<any>("/configurations/config.json")
    ).then((data) => {
      console.log(data);

      this.appConfig = data.appConfig;
    });
  }

  get telemetryApiUrl() {
    if (!this.appConfig) {
      throw Error("Config file not loaded!");
    }

    return this.appConfig.telemetryApiUrl;
   // return this.appConfig.telemetryApiUrlLocal;
  }

  get protectedResources() {
    if (!this.appConfig) {
      throw Error("Config file not loaded!");
    }
    return {
      telemetryAPI: {
        endpoint: this.appConfig.protectedResources.telemetryApiUrl.endpoint,
        scopes: [this.appConfig.protectedResources.telemetryApiUrl.scopes],
      },
      graphApi: {
        endpoint: this.appConfig.protectedResources.graphApi.endpoint,
        scopes: [this.appConfig.protectedResources.graphApi.scopes],
      },
    };
  }

  get auth() {
    if (!this.appConfig) {
      throw Error("Config file not loaded!");
    }
    return {
      clientId: this.appConfig.auth.clientId,
      authority: this.appConfig.auth.authority,
      redirectUri: this.appConfig.auth.redirectUri,
    };
  }

  get groups() {
    if (!this.appConfig) {
      throw Error("Config file not loaded!");
    }
    return {
      groups: {
        groupAdmin: this.appConfig.groups.groupAdmin,
        groupSalary: this.appConfig.groups.groupSalary,
      },
    };
  }
  get loginRequest() {
    if (!this.appConfig) {
      throw Error("Config file not loaded!");
    }
    return this.appConfig.loginRequest;
  }
}

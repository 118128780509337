import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";

import _ from 'lodash';
import { SettingsModel } from "src/app/models/administration/settings.model";

@Component({
  selector: "app-setting-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.css"],
})
export class SettingStatisticsTableComponent implements OnChanges, AfterViewChecked, OnInit {
  constructor(private cd: ChangeDetectorRef) { }

  @Input() settingsData: SettingsModel[];
  @Input() viewMode: string;

   //sending from child comp to parent
    @Output() settingItemEvent = new EventEmitter<SettingsModel>();


  tempList: any[];
  listOfProperties: string[];
  isChecked:boolean = false;



  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.groupSettingsData(this.settingsData) //Kan va fel ordning;

  }


  settingNewData(value: any) {
    //loopar igenom arrayen
    for(const n of value) {
      /* console.log(n) */
      this.settingItemEvent.emit(n);
  }
}

  groupSettingsData(a: SettingsModel[]) {

    //Using lodash, group data by period then sum each attribute
    if (a.length > 0) {
    let data = _(a).groupBy('period')
      .map(function (items, period) {
        return {
          Period: period,
          UnikaForetag: _.sumBy(items, 'uniqueCorporateIdentityNumberId'),
          UnikaInstallationer: _.sumBy(items, 'uniqueCustomerNumberId'),
          Husarbete: _.sumBy(items, 'flagHusarb'),
          AktiveratVon: _.sumBy(items, 'flagVo'),
          EUBas: _.sumBy(items, 'flagKptypEuBas'),
          NE: _.sumBy(items, 'flagKptypNe'),
          Bas9X: _.sumBy(items, 'flagKptypBas9x'),
          InvReceiver: _.sumBy(items, 'flagInvReceiver'),
          OrdReceiver: _.sumBy(items, 'flagOrdReceiver'),
          ScanReceiver: _.sumBy(items, 'flagScanReceiver'),
        };
      }).value();

    //Flip list so latest period comes first
    data.reverse()


    this.sortHeading(data);
    this.tempList = data;
    this.settingNewData(data);

  }
  else {

    if (a.length < 1) {

      _.forEach(this.tempList,(item, index) => {
       // console.log(item.Period)
        const period = item.Period;
        const arr =  _.zipObject(["Period","Unika Företag","Unika Installationer","Husarbete","Aktiverat Von","EU Bas","NE","Bas9X","Ta emot e-faktura","Ta emot e-order","E-faktura - scanning" ], [period,0,0,0,0,0,0,0,0,0,0]);

         this.tempList[index] = arr;

      })
    }
  }
  }

  sortHeading(settingsData: any) {

    //Take all properties of the first object in list
    const firstObjectInList = settingsData[0];
    const objectProperties = Object.keys(firstObjectInList);

    //Split each property name at capital letters and add a space then set it back in the list.
    for (let i = 0; i < objectProperties.length; i++) {
      objectProperties.splice(i,1, objectProperties[i].split(/(?=[A-Z])/).join(" "));
    }
    //Assign to list used as table columns header
    this.listOfProperties = objectProperties;

  }

  ngOnChanges() {
    this.groupSettingsData(this.settingsData);
    this.swapToPrecent();
  }

  swapToPrecent() {
    if(this.viewMode === 'Andel') {
      this.isChecked = true;
       this.settingsData.map((obj:any) => {
        let uniqueCorp = obj.UnikaForetag;
        let activeVon = obj.AktiveratVon;
        let hWork = obj.Husarbete;

        let _EUBas = obj.EUBas;
        let _NE = obj.NE;
        let _Bas9X = obj.Bas9X;

        let _InvReceiver = obj.InvReceiver;
        let _OrdReceiver = obj.OrdReceiver;
        let _ScanReceiver = obj.ScanReceiver;
        
        if(this.settingsData.length > 0 ) {
          this.calcToProcent(uniqueCorp, activeVon)
          this.calcToProcent(uniqueCorp, hWork)
          
          this.calcToProcent(uniqueCorp, _EUBas)
          this.calcToProcent(uniqueCorp, _NE)
          this.calcToProcent(uniqueCorp, _Bas9X)

          this.calcToProcent(uniqueCorp, _InvReceiver)
          this.calcToProcent(uniqueCorp, _OrdReceiver)
          this.calcToProcent(uniqueCorp, _ScanReceiver)
/*
         this.groupedSettingsData[obj] = this.calcToProcent(uniqueCorp, activeVon)
         // console.log(this.groupedSettingsData[obj] = this.calcToProcent(uniqueCorp, activeVon))
     console.log(this.groupedSettingsData[obj] =  (this.calcToProcent(uniqueCorp, activeVon) + ' AVON'))
         console.log(this.groupedSettingsData[obj] = this.calcToProcent(uniqueCorp, hWork) + ' HusArbete')
          this.groupedSettingsData[obj] = this.calcToProcent(uniqueCorp, activeVon) */
        }
      })
    }
  }
  calcToProcent(a: number, b: number) {

    if ((a === 0 && b === 0) || ((a === undefined || null) || b === undefined|| null))
    {
      return 0 + '%'
    }

    let calc = (a-b)
   // console.log(calc)
    let calc2 = (a - calc)
   // console.log(calc2)
    if (a === b) {
      return (a % b).toString() + '%';
    }
    else {
      return (Math.round((calc2 / a) * 100)).toString() + '%';
    }
  }




}

<div class="panel">
    <div class="panel-heading">
        <h1 class="panel-title">
            Visningsalternativ
        </h1>
    </div>
    <div class="panel-body">
        <form [formGroup]="viewOptions">

            <label for="view-One"><input id="view-One" value="Antal" type="radio" formControlName="selection"
                    (ngModelChange)="emitEvent()"><span class="selection">Antal</span>
            </label>

            <label for="view-Two"> <input id="view-Two" value="Andel" type="radio" formControlName="selection"
                    (ngModelChange)="emitEvent()"><span class="selection">Andel</span>
            </label>
        </form>
    </div>
</div>
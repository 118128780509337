import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SettingsModel } from "src/app/models/administration/settings.model";
import { UtilityService } from "src/app/services/utility/utility.service";
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";


@Component({
  selector: "app-setting-filter-checkbox",
  templateUrl: "./filter-checkbox.component.html",
  styleUrls: ["./filter-checkbox.component.css"],
})
export class SettingStatisticsFilterCheckboxComponent implements OnInit {

  @Input() settingsData?: SettingsModel[] = [];
  @Output() filterEmitter = new EventEmitter<SettingsModel[]>();

  filtredByra: any = [];
  settingsFiltered: SettingsModel[] = [];
  temporarySettings: SettingsModel[] = [];
  settingCheckboxLabels = { a200: true, a500: true, a1000: true, a2000: true, fakturering: true, forening: false, startByra: true };

 isChecked = false;


  constructor(private utilityService: UtilityService) {}

  settingsFilterChange() {

    this.settingsFiltered = this.temporarySettings?.filter(x  =>

      (x.variant === '0' && this.settingCheckboxLabels.a200) ||
      (x.variant === '5' && this.settingCheckboxLabels.a500) ||
      (x.variant === '1' && this.settingCheckboxLabels.a1000) ||
      (x.variant === '2' && this.settingCheckboxLabels.a2000) ||
      (x.variant === 'W' && this.settingCheckboxLabels.fakturering) ||
      (x.variant === 'F' && this.settingCheckboxLabels.forening)

    )

  console.log(this.settingsFiltered)
      this.filterEmitter.emit(this.settingsFiltered)

      if(this.settingsFiltered.length === 0) {
        const arr: any = Array.from({ length: 7 }).fill(0);
        this.settingsFiltered = arr;
          this.showToaster();
      }
  }
  onItemChange(event: { target: { value: string; }; }){

      //true
     let t = this.settingsFiltered.filter(t =>
      (t.flagByraStart == this.settingCheckboxLabels.startByra)
      )

      //false
      let s = this.settingsFiltered.filter(t =>
        (t.flagByraStart == !this.settingCheckboxLabels.startByra))



      if( event.target.value === 'true') {
       console.log(t)
       this.filterEmitter.emit(t)

     } else if(event.target.value === 'false' ) {
       console.log(s)
       this.filterEmitter.emit(s)

     } else if(event.target.value === 'both') {

      console.log('both')
      console.log(this.settingsFiltered)
      this.filterEmitter.emit(this.settingsFiltered)
     }



 }


 showToaster(): void {
    this.utilityService.showWarning("Ingen data kvar att filtrera", "Viktigt meddelande!");
}

  ngOnInit(): void {

    this.temporarySettings = this.settingsData;
    this.filtredByra = new Array<any>();

    this.settingsFilterChange();



  }
}

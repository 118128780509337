
<div class='card-section'>
   
    <div>
        <button  href="/">I understand, take me back</button>
    </div>
    <hr>
    <p>Your group memberships:</p>
    <ol>
        <li *ngFor="let group of groups">{{group}}</li>
    </ol>
</div>
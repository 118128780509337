import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UtilityService } from "src/app/services/utility/utility.service";
import { ApplicationVersions } from '../../models/administration/applicationversions.model';

@Component({
  selector: "app-version-filter-checkbox",
  templateUrl: "./filter-checkbox.component.html",
  styleUrls: ["./filter-checkbox.component.css"],
})
export class VersionStatisticsFilterCheckboxComponent implements OnInit {

  @Input() applicationVersions: ApplicationVersions[] = [];
  @Output() filterEmitter = new EventEmitter<ApplicationVersions[]>();

  applicationVersionsFiltered: ApplicationVersions[] = [];
  temporaryApplicationVersions: ApplicationVersions[] = [];

  checkboxLabels = { a200: true, a500: true, a1000: true, a2000: true, fakturering: true, forening: false };

  filterChange() {

    // Filter applicationversions depending on what checkboxes are checked


    this.applicationVersionsFiltered = this.temporaryApplicationVersions.filter(x =>
      (x.variant === '0' && this.checkboxLabels.a200) ||
      (x.variant === '5' && this.checkboxLabels.a500) ||
      (x.variant === '1' && this.checkboxLabels.a1000) ||
      (x.variant === '2' && this.checkboxLabels.a2000) ||
      (x.variant === 'W' && this.checkboxLabels.fakturering) ||
      (x.variant === 'F' && this.checkboxLabels.forening)
    );
    console.log(this.applicationVersionsFiltered)

    this.filterEmitter.emit(this.applicationVersionsFiltered);
    if(this.applicationVersionsFiltered.length === 0){
      console.log(this.applicationVersionsFiltered)
      this.showToaster();

    }
  }

  constructor(private utilityService: UtilityService) {}


  showToaster(){
    this.utilityService.showWarning("Ingen data kvar att filtrera", "Viktigt meddelande!");
}

  ngOnInit(): void {
    this.temporaryApplicationVersions = this.applicationVersions;
    this.filterChange();
  }
}

<div class="container-fluid">

  <div class="row">
    <div class="d-flex w-100 justify-content-center">
      <h1>Lön</h1>
    </div>
  </div>

  <div class="row">
      <!-- new datepicker -->
      <div class=" d-flex justify-content-between w-100">
       <div>
        <nz-date-picker nzMode="year" [(ngModel)]="selectedPeriod" (ngModelChange)="this.getSelectedByPeriod()">selectPeriod
        </nz-date-picker>
       </div>
        <app-export [salary]="this.salary"></app-export>
      </div>

  </div>



<div class="row">

  <div class="table-responsive w-100" id="htmlData">
    <table class="table" id="excel-table">
      <thead>
        <tr class="d-flex">
          <td scope="col" style="text-align: center; cursor: pointer; width: 20%">
            Period
            <small class="font-weight-light font-italic">(Föregående år)</small>
          </td>
          <td scope="col" style="text-align: center; cursor: pointer; width: 20%">
            Lön100
          </td>
          <td scope="col" style="text-align: center; cursor: pointer; width: 20%">
            Lön300
          </td>
          <td scope="col" style="text-align: center; cursor: pointer; width: 20%">
            Lön600
          </td>
          <td style="text-align: center; cursor: pointer; width: 20%">Total</td>
        </tr>
      </thead>

      <div class="errorMsg" *ngIf="!dataAvailable"><h1>Ingen data att visa!</h1></div>

      <ng-container *ngIf="oldSalary.length > 0; then first; else second"></ng-container>
      <ng-template #first>
      <tbody *ngFor="let s of salary | filter: searchTerm; let i = index" class="text-center justify-content-center">
        <tr class="d-flex" *ngIf="dataAvailable">
          <td style="width: 20%">
            {{ s?.period | monthYear }}
            <small class="font-weight-light font-italic">({{ oldSalary[i]?.period}})</small>
          </td>

          <td class="d-flex flex-row justify-content-center" style="width: 20%">
            <p class="p-1">{{ s?.payslip100 }}</p>
            (
            <p class="d-flex flex-row justify-content-center p-1" [ngStyle]="{
                color:
                  isBigger === s?.payslip100 > oldSalary[i]?.payslip100
                    ? 'green'
                    : 'red'
              }">
              {{ calcToProcent(oldSalary[i]?.payslip100, s?.payslip100)}}
              <i [ngClass]="{
                  'fa-arrow-up':
                    isBigger === s?.payslip100 > oldSalary[i]?.payslip100,
                  'fa-arrow-down':
                    isBigger === s?.payslip100 < oldSalary[i]?.payslip100
                }" class="fa p-2">
              </i>
            </p>
            )
          </td>

          <td class="d-flex flex-row justify-content-center" style="width: 20%">
            <p class="p-1">{{ s?.payslip300 }}</p>
            (
            <p class="d-flex flex-row justify-content-center p-1" [ngStyle]="{
                color:
                  isBigger === s?.payslip300 > oldSalary[i]?.payslip300
                    ? 'green'
                    : 'red'
              }">
              {{ calcToProcent(oldSalary[i]?.payslip300, s?.payslip300) }}
              <i [ngClass]="{
                  'fa-arrow-up':
                    isBigger === s?.payslip300 > oldSalary[i]?.payslip300,
                  'fa-arrow-down':
                    isBigger === s?.payslip300 < oldSalary[i]?.payslip300
                }" class="fa p-2">
              </i>
            </p>
            )
          </td>

          <td class="d-flex flex-row justify-content-center" style="width: 20%">
            <p class="p-1">{{ s?.payslip600 }}</p>
            (
            <p class="d-flex flex-row justify-content-center p-1" [ngStyle]="{
                color:
                  isBigger === s?.payslip600 > oldSalary[i]?.payslip600
                    ? 'green'
                    : 'red'
              }">
              {{ calcToProcent(oldSalary[i]?.payslip600, s?.payslip600) }}
              <i [ngClass]="{
                  'fa-arrow-up':
                    isBigger === s?.payslip600 > oldSalary[i]?.payslip600,
                  'fa-arrow-down':
                    isBigger === s?.payslip600 < oldSalary[i]?.payslip600
                }" class="fa p-2">
              </i>
            </p>
            )
          </td>

          <td class="d-flex flex-row justify-content-center" style="width: 20%">
            <p class="p-1">{{ s?.payslip100 + s?.payslip300 + s?.payslip600 }}</p>

            (
            <p class="d-flex flex-row justify-content-center p-1" [ngStyle]="{
                color:
                  isBigger ===
                  s?.payslip100 + s?.payslip300 + s?.payslip600 >
                    oldSalary[i]?.payslip100 +
                      oldSalary[i]?.payslip300 +
                      oldSalary[i]?.payslip600
                    ? 'green'
                    : 'red'
              }">
              {{
              calcToProcent(
              countTotal(
              oldSalary[i]?.payslip100,
              oldSalary[i]?.payslip300,
              oldSalary[i]?.payslip600
              ),
              countTotal(s?.payslip100, s?.payslip300, s?.payslip600)
              )
              }}

              <i [ngClass]="{
                  'fa-arrow-up':
                    isBigger ===
                    s?.payslip100 + s?.payslip300 + s?.payslip600 >
                      oldSalary[i]?.payslip100 +
                        oldSalary[i]?.payslip300 +
                        oldSalary[i]?.payslip600,
                  'fa-arrow-down':
                    isBigger ===
                    s?.payslip100 + s?.payslip300 + s?.payslip600 <
                      oldSalary[i]?.payslip100 +
                        oldSalary[i]?.payslip300 +
                        oldSalary[i]?.payslip600
                }" class="fa p-2">
              </i>
            </p>
            )
          </td>
        </tr>
      </tbody>
    </ng-template>

    <ng-template #second>
      <tbody *ngFor="let s of salary | filter: searchTerm; let i = index" class="text-center justify-content-center">
        <tr class="d-flex" >
          <td style="width: 20%">
            {{ s?.period | monthYear }}

          </td>

          <td class="d-flex flex-row justify-content-center" style="width: 20%">
            <p class="p-1">{{ s?.payslip100 }}</p>
          </td>

          <td class="d-flex flex-row justify-content-center" style="width: 20%">
            <p class="p-1">{{ s?.payslip300 }}</p>
          </td>

          <td class="d-flex flex-row justify-content-center" style="width: 20%">
            <p class="p-1">{{ s?.payslip600 }}</p>
          </td>

          <td class="d-flex flex-row justify-content-center" style="width: 20%">
            <p class="p-1">{{ s?.payslip100 + s?.payslip300 + s?.payslip600 }}</p>
          </td>
        </tr>
      </tbody>
    </ng-template>


    </table>
  </div>
</div>


</div>
















<!-- [appSort]="salary" data-order="desc" data-name="payslip600" -->


<table class="table" id="htmlData">
  <thead>
    <tr>
      <td> Period </td>
      <td *ngFor="let version of latestVersions"> {{version}} </td>
      <td> Övriga SQL </td>
      <td> Non SQL </td>
      <td> Total </td>
    </tr>
  </thead>
  <tbody *ngIf="groupedVersionsData?.length > 0">
    <tr *ngFor="let item of groupedVersionsData" >
      <td> {{item.period | monthYear }} </td>
      <td>
        <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="firstVPercentage">
          {{item.firstVersion}}
        </ng-template>
        <ng-template #firstVPercentage>
          {{ calcToProcent(item.total,item.firstVersion) }}
        </ng-template>
      </td>

      <td> <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="secondVPercentage">
        {{item.secondVersion}}
      </ng-template>
      <ng-template #secondVPercentage>
        {{ calcToProcent(item.total,item.secondVersion) }}
      </ng-template></td>

      <td> <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="thirdVPercentage">
        {{item.thirdVersion}}
      </ng-template>
      <ng-template #thirdVPercentage>
        {{ calcToProcent(item.total,item.thirdVersion) }}
      </ng-template> </td>

      <td> <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="fourthVPercentage">
        {{item.fourthVersion}}
      </ng-template>
      <ng-template #fourthVPercentage>
        {{ calcToProcent(item.total,item.fourthVersion) }}
      </ng-template> </td>

      <td> <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="fifthVPercentage">
        {{item.fifthVersion}}
      </ng-template>
      <ng-template #fifthVPercentage>
        {{ calcToProcent(item.total,item.fifthVersion) }}
      </ng-template> </td>

      <td> <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="sixthVPercentage">
        {{item.sixthVersion}}
      </ng-template>
      <ng-template #sixthVPercentage>
        {{ calcToProcent(item.total,item.sixthVersion) }}
      </ng-template> </td>

      <td> <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="seventhVPercentage">
        {{item.seventhVersion}}
      </ng-template>
      <ng-template #seventhVPercentage>
        {{ calcToProcent(item.total,item.seventhVersion) }}
      </ng-template> </td>

      <td> <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="otherVPercentage">
        {{item.otherSQLVersions}}
      </ng-template>
      <ng-template #otherVPercentage>
        {{ calcToProcent(item.total,item.otherSQLVersions) }}
      </ng-template> </td>

      <td> <ng-template [ngIf]="viewMode === 'Antal'" [ngIfElse]="nonVPercentage">
        {{item.nonSQLVersions}}
      </ng-template>
      <ng-template #nonVPercentage>
        {{ calcToProcent(item.total,item.nonSQLVersions) }}
      </ng-template> </td>

      <td> {{item.total}} </td>
    </tr>
  </tbody>
</table>

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { HomeComponent } from "./home/home.component";
import { GroupGuardService } from "./group-guard.service";
import { ErrorComponent } from "./error/error.component";
import { AppConfigService } from "./app-config/appconfig.service";

/**
 * MSAL Angular can protect routes in your application
 * using MsalGuard. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#secure-the-routes-in-your-application
 */
const routes: Routes = [
  { path: "", component: HomeComponent, pathMatch: "full",
  canActivate: [MsalGuard], },
  {
    path: "",
    loadChildren: () =>
      import("./modules/admin/admin.module").then((m) => m.AdminModule),
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      expectedGroup: "ADMIN",
    },
    title: "Admin"
  },
  {
    path: "",
    loadChildren: () =>
      import("./modules/lon/lon.module").then((m) => m.LonModule),
    canActivate: [MsalGuard, GroupGuardService],
    data: {
      expectedGroup: "LON",
    },
    title: "Lon"
  },

  { path: "404", component: ErrorComponent },
  { path: "**", redirectTo: "/404" },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? "enabledNonBlocking" : "disabled",
    }),
  ],
  providers: [[AppConfigService]],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, NgModule } from '@angular/core';
import { OnInit, Inject, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppConfigService } from '../app-config/appconfig.service';
import { result } from 'lodash';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']

})


export class NavMenuComponent {

  groupSalary: any;
  groupAdmin: any;
  isLoggedInAsSalary: boolean;
  isLoggedInAsAdmin: boolean;

  openProfileDropdown = false;

  user: User = {
    name: '',
    mail: '',
    groups: []
  };

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();


  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private appConfig: AppConfigService

  ) { }


  ngOnInit(): void {
    this.groupSalary = `${this.appConfig.groups.groups.groupSalary}`;
    this.groupAdmin = `${this.appConfig.groups.groups.groupAdmin}`;

    this.isIframe = window !== window.parent && !window.opener;

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);

        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
        this.isLoggedInAndAuthorized(this.authService.instance.getActiveAccount()?.idTokenClaims);
      });

  }

  isLoggedInAndAuthorized(claims: any) {
    if (this.authService.instance.getActiveAccount() != null) {
      claims.groups.filter(group => {
        if (group === this.groupSalary) {
          this.isLoggedInAsSalary = true;
        }
        if (group === this.groupAdmin) {
          this.isLoggedInAsAdmin = true;
        }
      }
      );
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  logout() {
    this.authService.logout();
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  getClaims(claims: any) {
    this.user.name = claims.name;
    this.user.mail = claims.preferred_username;
    this.user.groups = claims.groups;
    console.log(claims);
  }



  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}

interface User {
  name: string;
  mail: string;
  groups: [];
}

<!--For rounding of percentages-->
<script src="https://unpkg.com/largest-remainder-round"></script>

<table class="table" id="htmlData">
  <thead>
    <tr>
      <!-- <td *ngFor="let property of listOfProperties | keyvalue" >{{property.value}}</td> -->


      <td colspan="1"></td>
      <td colspan="1" class="borderRight"></td>
      <td colspan="2" class="borderRight borderTop">Danske Bank</td>
      <td colspan="2" class="borderRight borderTop">Handelsbanken</td>
      <td colspan="3" class="borderRight borderTop">Nordea</td>
      <td colspan="1" class="borderRight borderTop">Nordea+Tjänst</td>
      <td colspan="2" class="borderRight borderTop">SEB</td>
      <td colspan="2" class="borderRight borderTop">Swedbank</td>
      <td colspan="1" class="borderRight borderTop">DNB</td>
      <td colspan="1" class="borderRight borderTop">LF</td>


    </tr>


    <tr>
      <td colspan="1" class="borderLeft borderRight borderCenter borderTop">Period</td>
      <td colspan="1" class="borderRight borderCenter borderTop">Annan Bank</td>
      <td colspan="1" class="borderCenter">LB</td>
      <td colspan="1" class="borderCenter borderRight">XML</td>
      <td colspan="1" class="borderCenter">LB</td>
      <td colspan="1" class="borderCenter borderRight">XML</td>
      <td colspan="1" class="borderCenter">LB</td>
      <td colspan="1" class="borderCenter">XML</td>
      <td colspan="1" class="borderCenter borderRight">PO3</td>
      <td colspan="1" class="borderCenter borderRight">XML</td>
      <td colspan="1" class="borderCenter">LB</td>
      <td colspan="1" class="borderCenter borderRight">XML</td>
      <td colspan="1" class="borderCenter">LB</td>
      <td colspan="1" class="borderCenter borderRight">XML</td>
      <td colspan="1" class="borderCenter borderRight">XML</td>
      <td colspan="1" class="borderCenter borderRight">XML</td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of tempList">
      <td>{{ item.Period }}</td>
      <td>{{ item.FlagAnnanBank }}</td>
      <td>{{ item.FlagDanskeBankLB }}</td>
      <td>{{ item.FlagDanskeBankXML }}</td>
      <td>{{ item.FlagHandelsBankenLB }}</td>
      <td>{{ item.FlagHandelsBankenXML }}</td>
      <td>{{ item.FlagNordeaNOTCALB }}</td>
      <td>{{ item.FlagNordeaNOTCAXML }}</td>
      <td>{{ item.FlagNordeaNOTCAPO3 }}</td>
      <td>{{ item.FlagNordeaCAXML }}</td>
      <td>{{ item.FlagSebLB }}</td>
      <td>{{ item.FlagSebXML }}</td>
      <td>{{ item.FlagSwedBankLB }}</td>
      <td>{{ item.FlagSwedBankXML }}</td>
      <td>{{ item.FlagDNBXML }}</td>
      <td>{{ item.FlagLFXML }}</td>
    </tr>
  </tbody>
</table>

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

/*
* Transforms date in format YYYYMM to full name + YYYY
*/

@Pipe({
  name: 'monthYear',
})
export class MonthYearPipe implements PipeTransform {
  transform(value: string) {

    const regExp = /^[0-9]{4}[0-9]{2}$/;
    const valid = regExp.test(value);

    if (valid) {

      let year: any;
      let month: any;

      month = value.substr(-2);
      year = value.substr(0, 4);

      const newDate = new Date(year, month - 1);

      const monthName = newDate.toLocaleString('default', { month: 'short' });
      return monthName + ' ' + newDate.getFullYear();
    } else {
      return value;
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UtilityService } from "src/app/services/utility/utility.service";
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";
import { BankSettingsModel } from "src/app/models/administration/bankSettings.model";


@Component({
  selector: "app-banksetting-filter-checkbox",
  templateUrl: "./filter-checkbox.component.html",
  styleUrls: ["./filter-checkbox.component.css"],
})
export class BankSettingStatisticsFilterCheckboxComponent implements OnInit {

  @Input() bankSettingsData?: BankSettingsModel[] = [];
  @Output() filterEmitter = new EventEmitter<BankSettingsModel[]>();

  filtredByra: any = [];
  bankSettingsFiltered: BankSettingsModel[] = [];
  temporarySettings: BankSettingsModel[] = [];
  settingCheckboxLabels = { a200: true, a500: true, a1000: true, a2000: true, fakturering: true, forening: false, startByra: true };

 isChecked = false;


  constructor(private utilityService: UtilityService) {}

  settingsFilterChange() {

    this.bankSettingsFiltered = this.temporarySettings?.filter(x  =>

      (x.variant === '0' && this.settingCheckboxLabels.a200) ||
      (x.variant === '5' && this.settingCheckboxLabels.a500) ||
      (x.variant === '1' && this.settingCheckboxLabels.a1000) ||
      (x.variant === '2' && this.settingCheckboxLabels.a2000) ||
      (x.variant === 'W' && this.settingCheckboxLabels.fakturering) ||
      (x.variant === 'F' && this.settingCheckboxLabels.forening)

    )

  console.log(this.bankSettingsFiltered)
      this.filterEmitter.emit(this.bankSettingsFiltered)

      if(this.bankSettingsFiltered.length === 0) {
        const arr: any = Array.from({ length: 7 }).fill(0);
        this.bankSettingsFiltered = arr;
          this.showToaster();
      }
  }
  onItemChange(event: { target: { value: string; }; }){

      //true
     let t = this.bankSettingsFiltered.filter(t =>
      (t.flagByraStart == this.settingCheckboxLabels.startByra)
      )

      //false
      let s = this.bankSettingsFiltered.filter(t =>
        (t.flagByraStart == !this.settingCheckboxLabels.startByra))



      if( event.target.value === 'true') {
       console.log(t)
       this.filterEmitter.emit(t)

     } else if(event.target.value === 'false' ) {
       console.log(s)
       this.filterEmitter.emit(s)

     } else if(event.target.value === 'both') {

      console.log('both')
      console.log(this.bankSettingsFiltered)
      this.filterEmitter.emit(this.bankSettingsFiltered)
     }



 }


 showToaster(): void {
    this.utilityService.showWarning("Ingen data kvar att filtrera", "Viktigt meddelande!");
}

  ngOnInit(): void {

    this.temporarySettings = this.bankSettingsData;
    this.filtredByra = new Array<any>();

    this.settingsFilterChange();



  }
}

<div class="container-fluid">
  <div *ngIf="!loading" class="row">
    <div *ngIf="this.applicationVersions" class="col-9 d-flex">
      <h1>Versionsstatistik</h1>
      <span class="insertDate"> Data är från: {{ insertDate | date }} </span>
      <app-export
      *ngIf="versionsData"
        [versionsData]="versionsData"
        class="ml-auto"
       ></app-export>
      <app-version-table
      class="table-responsive w-100 h-auto"
      [applicationVersions]="this.applicationVersions"
        [viewMode]="this.viewMode"
        (versionItemEvent)="addVersionData($event)"
        >
      </app-version-table>
    </div>
    <div class="col-3">
      <app-version-radiogroup
      (viewModeEmitter)="this.viewMode = $event">
      </app-version-radiogroup>
      <app-version-filter-checkbox
       [applicationVersions]="this.applicationVersions"
       (filterEmitter)="this.applicationVersions = $event">
      </app-version-filter-checkbox>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AdministrationService } from '../services/administration/administration.service';
import { ApplicationVersions } from '../models/administration/applicationversions.model';
import { UtilityService } from '../services/utility/utility.service';


@Component({
  selector: 'app-administration-version-statistics',
  templateUrl: './administration-version-statistics.component.html',
  styleUrls: ['./administration-version-statistics.component.css']
})
export class AdministrationVersionStatisticsComponent implements OnInit {

  // for sending data
  versionsData: any = [];

  addVersionData(newItem: string) {
    this.versionsData.push(newItem);
    console.log(this.versionsData)
  }

  applicationVersions: ApplicationVersions[];
  loading: boolean = true;
  viewMode: string;
  insertDate: Date;

  constructor(private adminService: AdministrationService, private utilityService: UtilityService) { }

  getAdminData() {
    const startPeriod = new Date();
    const endPeriod = new Date();

    startPeriod.setFullYear(endPeriod.getFullYear() - 1);
    startPeriod.setMonth(endPeriod.getMonth());

    this.adminService.getAdminData(startPeriod, endPeriod).subscribe(data => {
      this.applicationVersions = data;
      this.setInsertDate(data);
      this.loading = false;
    });

  }

  setInsertDate(data: ApplicationVersions[]) {
    // get the insertDate of the last inserted row (from datacollection project)
    this.insertDate = new Date(Math.max.apply(null, data.map(function(e) {
      return new Date(e.insertDate);
    })));
  }

  ngOnInit() {
    this.getAdminData();
  }

}


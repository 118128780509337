import { Component, Input, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ApplicationVersionsGrouped } from 'src/app/models/administration/applicationversions.model';
import { UtilityService } from './../../services/utility/utility.service';
import * as FileSaver from 'file-saver';
          declare var saveAs: typeof FileSaver.saveAs;

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent {

  @Input() salary: any;
  @Input()versionsData: any;
  @Input() settingData: any;

  fileName = 'salarySheet.xlsx';


  constructor(private utService: UtilityService) {


  }

  exportTo(value: string) {
    switch (value) {
      case 'pdf':
        this.openPDF();
        break;
      case 'excel':
        this.exportExcel();
        break;
      case 'excelAdmin':
        this.exportExcelAdmin();
        break;
      case 'excelAdminSetting':
        this.exportExcelAdminSetting();
        break;
      case 'csv':
        this.exportToCSV();
        break;
    }
  }


  public openPDF(): void {
    const DATA = document.getElementById('htmlData');

    html2canvas(DATA).then(canvas => {

        const fileWidth = 208;
        const fileHeight = canvas.height * fileWidth / canvas.width;

        const FILEURI = canvas.toDataURL('image/png');
        const PDF = new jsPDF('p', 'mm', 'a4');
        const position = 0;
        PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);

        PDF.save('rapport.pdf');
    });
    }


  exportToCSV() {
    new ngxCsv(this.salary, 'salary');
  }

    //excel button click functionality
    exportExcelAdminSetting() {
      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.settingData); // Version Data
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "admin-setting");
      });
    }



  //excel button click functionality
  exportExcelAdmin() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.versionsData); // Version Data
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "admin-version");
    });
  }


exportExcel() {
  import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.salary); // Sale Data
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "lön");
  });
}
saveAsExcelFile(buffer: any, fileName: string): void {
  import("file-saver").then(FileSaver => {

  let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(
      data,
      fileName + "_export_" + new Date().getUTCFullYear() + EXCEL_EXTENSION
    );
  });
}


}


/* exportToExcel() {

  const element = document.getElementById('excel-table');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);


  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');


  XLSX.writeFile(wb, this.fileName);
}
 */
